import React from 'react'
import { Link } from 'gatsby'

import {  faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default () => (
  <div className="social-container">
    <ul className="social">
      <li className="social-item">
        <a target="_blank" href="mailto:stephen.noble0514@gmail.com"><FontAwesomeIcon icon={ faEnvelope }/></a>
      </li>
      <li className="social-item">
        <a target="_blank" href="https://www.linkedin.com/in/hsnoble/"><FontAwesomeIcon icon={ faLinkedin }/></a>
      </li>
      <li className="social-item">
        <a target="_blank" href="https://github.com/hsnoble"><FontAwesomeIcon icon={ faGithub }/></a>
      </li>
    </ul>
  </div>
)