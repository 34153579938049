import React from 'react'
import bgVid from '../media/bg.mp4'
export default ({ children }) => (
  <div className="container-main">
    <div className="content-container">
      <video className="bg-video" src={bgVid} loop autoPlay muted playsInline>
      </video>
    
      <div className="overlay scroll-container">
        <div className="main-content ">
          {children}
        </div>
      </div>
    </div>
  </div>
)
