import React from 'react'
import { Link } from 'gatsby'

class NavigationParent extends React.Component{
  render (){
    return(
      <nav role="navigation">
        <ul className="navigation">
          {this.props.children}
        </ul>
      </nav>
    );
  }
}

export default NavigationParent;