import React from 'react'
import Container from './container'
import NavigationParent from './navigationParent'
import NavigationChild from './navigation'
import logo from '../media/logo.svg'
import Social from '../components/social'

import $ from 'jquery/dist/jquery.slim'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Container>
        {/* Header */}
        <div className="header-container lg-wrap">
          <div className="logo-container">
            <img src={logo}/>
          </div>
          <NavigationParent>
            <NavigationChild link='#section-home' label='Home'/>
            <NavigationChild link='#section-about' label='About'/>
            <NavigationChild link='#section-work' label='Work'/>
            <NavigationChild link='#section-contact' label='Contact'/>
          </NavigationParent>
          <div class="placeholder"></div>
        </div>
        {/* Body */}
        {children}
        {/* Footer */}
        <div className="footer-container lg-wrap">
          <Social/>
        </div>
      </Container>
    )
  }
}

export default Template
