import React from 'react'
import { Link } from 'gatsby'

class NavigationChild extends React.Component{
  render (){
    var label = this.props.label;
    var link = this.props.link;
    return(
      <li className="navigationItem">
        <Link to={ link }>{ label }</Link>
      </li>
    );
  }
}

export default NavigationChild;